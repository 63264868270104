import React from "react"
import './styles.css';

export function PagError404() {
    return (
        <div id="pages_404">


           
            <section className="error">
                <div className="error__content">
                    <div className="error__message message">
                        <h1 className="message__title">Page Not Found</h1>
                        <p className="message__text">Link quebrado</p>
                    </div>
                    <div className="error__nav e-nav">
                        <a href="/" className="e-nav__link" />
                    </div>
                </div>

            </section>
        </div>
    )
}