import React from 'react';
import './styles.css';

import logo from "../assets/Logo_Canon.png"

const WelCome = () => {
  return (
    <div id="page-home" >
      <div className="content" >
        <header>
          <img width={"20%"} src={logo} alt="Canon" />
        </header>

        <main>
          <h1>ATENDIMENTO MÉDICO PAPERLESS</h1>
          <p>Uma plataforma de gestão documental e de assinaturas 100% digital, que integra todo o
            processo de atendimento para clínicas e hospitais.</p>
        </main>
      </div>
      <p hidden>Imagem de <a href="https://br.freepik.com/vetores-gratis/mulher-verificar-lista-cheque-gigante_4105860.htm#query=prontuario&position=14&from_view=search&track=sph">Freepik</a></p>
    </div>
  )
}

export default WelCome;