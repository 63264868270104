import React, { ReactElement, useEffect, useState } from 'react'
import { Viewer, Worker, RenderPage, RenderPageProps } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { RenderCurrentScaleProps, RenderZoomInProps, RenderZoomOutProps, zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';



import SignatureCanvas from 'react-signature-canvas'
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';


type CanvasProps = {
    handleUrl: string;
    docno: number;
    sigPad: any | null;
    pag: number;
    id: string;
}

type Props = {
    data: CanvasProps;
    Signatures: (data: any) => void;
    ClearSignature: (data: any) => void;
    handleShowMenu: () => void;
    classdd: string;
    isVisible: boolean;
    isActiveAssinar: boolean;
    isActiveScroll: boolean;
    handleClickAssinatura: (tipo: string) => void;
    showImagem: (items: any) => void;
    imagems: Imagems[]
}



interface CustomizeZoomButtonsExampleProps {
    fileUrl: string;
    renderPage: RenderPage | undefined
}

interface Imagems {
    docNo: number,
    url: URLS[],
    pag?: number,
    id: string;
}

interface URLS {
    docNo?: number,
    img: String | undefined,
    pag?: number,
    id?: string;
}


const CustomizeZoomButtonsExample: React.FC<CustomizeZoomButtonsExampleProps> = ({ fileUrl, renderPage }) => {
    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

    return (
        <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
                <div style={{ padding: '0px 2px' }}>
                    <ZoomOut>
                        {(props: RenderZoomOutProps) => (
                            <button
                                name={"zoomOut"}
                                style={{
                                    backgroundColor: '#357edd',
                                    border: 'none',
                                    borderRadius: '4px',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    padding: '8px',
                                    height: "4vh",
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}

                                onClick={() => {
                                    props.onClick()
                                    console.log(props)
                                }}

                            >
                                <BsZoomOut size={16} style={{ marginRight: "10px" }} />
                                Zoom out
                            </button>
                        )}
                    </ZoomOut>
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <CurrentScale>
                        {(props: RenderCurrentScaleProps) => <>{`${Math.round(props.scale * 100)}%`}</>}
                    </CurrentScale>
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <ZoomIn>
                        {(props: RenderZoomInProps) => (
                            <button
                                name='zoomIn'
                                style={{
                                    backgroundColor: '#357edd',
                                    border: 'none',
                                    borderRadius: '4px',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    padding: '8px',
                                    height: "4vh",
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}

                                onClick={() => {
                                    props.onClick()
                                    console.log(props)
                                }}
                            >
                                <BsZoomIn size={16} style={{ marginRight: "10px" }} />
                                Zoom in
                            </button>
                        )}
                    </ZoomIn>
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <Viewer renderPage={renderPage}
                    defaultScale={1.3}
                    fileUrl={fileUrl}
                    plugins={[zoomPluginInstance]}
                />
            </div>
        </div>
    );
};

export default function ViewPDF({
    data,
    Signatures,
    ClearSignature,
    handleShowMenu,
    classdd,
    isVisible,
    isActiveAssinar,
    isActiveScroll,
    handleClickAssinatura,
    showImagem,
    imagems
}: Props) {

    let canvasRef: SignatureCanvas | null;

    function scrolls() {
        if (!isActiveAssinar) {
            const x = document.getElementById(`${data.docno}-${data.pag}`)
            x?.style.removeProperty("touch-action")
        }
    }

    const renderPage: RenderPage = (props: RenderPageProps) => (

        <>

            {props.canvasLayer.children}

            {
                <div id="contentx"
                    style={{
                        zIndex: 99999,
                        backgroundColor: "transparent",
                        translate: `${isVisible ? "0%" : "0px"}`,

                    }}
                >



                    {/*<canvas ref={canvasRef}>esta aqui</canvas>*/}

                    <>
                        {
                            isActiveAssinar ? canvasRef?.on() : canvasRef?.off()

                        }
                        {
                            scrolls()
                        }
                    </>


                    <SignatureCanvas
                        ref={ref => (canvasRef = ref)}
                        key={data.docno}
                        penColor="#000"
                        velocityFilterWeight={0}
                        dotSize={1}
                        throttle={1}
                        maxWidth={1}
                        canvasProps={{
                            width: props.width,
                            height: props.height,
                            className: `xx-${data.docno}`,
                            id: `${data.docno}-${data.pag}`,

                        }}
                        onEnd={e => {
                            Signatures({ docno: data.docno, signature: canvasRef?.toDataURL(), pag: data.pag, id: data.id })
                            showImagem({ docNo: data.docno, url: canvasRef?.toDataURL(), pag: data.pag, id: data.id })
                        }}
                    />




                    {

                        <>
                            {imagems.length > 0 &&
                                imagems.map(values => {
                                    if (values.docNo === data.docno && values.pag === data.pag) {
                                        return (
                                            values.url.length > 0 &&
                                            values.url.map((img, index) => {
                                                return <img key={index} src={`${img.img}`} alt="Assinatura"
                                                    style={{
                                                        backgroundColor: "transparent", position: "absolute",
                                                        left: "0",
                                                        top: "0",
                                                        width: "100%",
                                                        zIndex: -1,
                                                    }}
                                                />
                                            })
                                        )
                                    }
                                })
                            }
                        </>
                    }




                </div>
            }

            {props.annotationLayer.children}
            {props.textLayer.children}

        </>

    );

    function Clear() {
        canvasRef?.clear()
        const DataSignature = {
            docno: data.docno, signature: canvasRef?.toDataURL(), pag: data.pag,
            id: data.id
        }
        ClearSignature(DataSignature)
    }







    useEffect(() => {
        isActiveAssinar ? canvasRef?.on() : canvasRef?.off();
    }, [isActiveAssinar, isActiveScroll])



    return (
        < div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: 'auto',
            }}
        >

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                {
                    <CustomizeZoomButtonsExample renderPage={renderPage} fileUrl={data.handleUrl} />
                }
                <button type="button"
                    className="button gray"
                    style={{ cursor: false ? "not-allowed" : "pointer", "marginTop": "10px" }}
                    onClick={() => Clear()}
                >
                    Limpar assinatura
                </button>
            </Worker>
        </div >
    );
};




