import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Home } from './page/Home';
import WelCome from './page/WelCome';
import { PagError404 } from './page/PagError404'

export default function Rotas() {
    return (
        <Routes>
            <Route path="/" element={<WelCome />} />
            <Route path="/:id/:tenant" element={<Home />} />
            <Route path="/:token" element={<Home />} />
            <Route path="/404" element={<PagError404 />} />
        </Routes>
    );
}
