import React from 'react';
import Rotas from './routes';

function App() {
  return (
    <Rotas />
  );
}
export let popupWindow : any | Window = null;
export default App;
