import axios from "axios"; 

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const apiHomo = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}v2/`,
})


